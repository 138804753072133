var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-card',[_c('section',{staticClass:"mt-2"},[_c('vue-good-table',{staticClass:"mt-3",attrs:{"is-loading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.notification,"style-class":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'created_at')?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime( props.formattedRow[props.column.field] ))+" ")]):(
              props.column.field === 'created_by.full_name' &&
                _vm.isChampionPortal
            )?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
                name: 'champion-program-participant',
                params: {
                  id: _vm.defaultProgramId,
                  participantId: props.row.created_by.id,
                },
              }},nativeOn:{"click":function($event){return _vm.markAsRead(props.row)}}},[_vm._v(" "+_vm._s(props.row.created_by.full_name)+" ")])],1):(
              props.column.field === 'latest_message.0.subject' &&
                _vm.isParticipantPortal
            )?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[(
                props.row.notifiable_type !== _vm.notificationType.PROGRAM_MATCH
              )?_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
                name: 'participant-messages',
                params: {
                  clientSlug: _vm.$route.params.clientSlug,
                  programPath: _vm.$route.params.programPath,
                },
              }},nativeOn:{"click":function($event){return _vm.markAsRead(props.row)}}},[_vm._v(" "+_vm._s(props.row.latest_message[0].subject)+" ")]):_c('router-link',{staticClass:"table-primary-link",attrs:{"to":{
                name: 'participant-home',
                params: {
                  clientSlug: _vm.$route.params.clientSlug,
                  programPath: _vm.$route.params.programPath,
                },
              }},nativeOn:{"click":function($event){return _vm.markAsRead(props.row)}}},[_vm._v(" "+_vm._s(props.row.latest_message[0].subject)+" ")])],1):(
              props.column.field === 'read_at' &&
                props.formattedRow[props.column.field]
            )?_c('div',{staticClass:"justify-content-between flex-wrap",attrs:{"cols":"12","md":"2"}},[_vm._v(" "+_vm._s(_vm.localeDateStringFromIsoDateTime( props.formattedRow[props.column.field] ))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }