<template>
  <section>
    <b-card>
      <section class="mt-2">
        <vue-good-table
          class="mt-3"
          :is-loading="isLoading"
          :columns="columns"
          :rows="notification"
          style-class="vgt-table striped"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <div
              v-if="props.column.field === 'created_at'"
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              {{
                localeDateStringFromIsoDateTime(
                  props.formattedRow[props.column.field]
                )
              }}
            </div>
            <div
              v-else-if="
                props.column.field === 'created_by.full_name' &&
                  isChampionPortal
              "
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              <router-link
                :to="{
                  name: 'champion-program-participant',
                  params: {
                    id: defaultProgramId,
                    participantId: props.row.created_by.id,
                  },
                }"
                class="table-primary-link"
                @click.native="markAsRead(props.row)"
              >
                {{ props.row.created_by.full_name }}
              </router-link>
            </div>
            <div
              v-else-if="
                props.column.field === 'latest_message.0.subject' &&
                  isParticipantPortal
              "
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              <router-link
                v-if="
                  props.row.notifiable_type !== notificationType.PROGRAM_MATCH
                "
                :to="{
                  name: 'participant-messages',
                  params: {
                    clientSlug: $route.params.clientSlug,
                    programPath: $route.params.programPath,
                  },
                }"
                class="table-primary-link"
                @click.native="markAsRead(props.row)"
              >
                {{ props.row.latest_message[0].subject }}
              </router-link>
              <router-link
                v-else
                :to="{
                  name: 'participant-home',
                  params: {
                    clientSlug: $route.params.clientSlug,
                    programPath: $route.params.programPath,
                  },
                }"
                class="table-primary-link"
                @click.native="markAsRead(props.row)"
              >
                {{ props.row.latest_message[0].subject }}
              </router-link>
            </div>
            <div
              v-else-if="
                props.column.field === 'read_at' &&
                  props.formattedRow[props.column.field]
              "
              cols="12"
              md="2"
              class="justify-content-between flex-wrap"
            >
              {{
                localeDateStringFromIsoDateTime(
                  props.formattedRow[props.column.field]
                )
              }}
            </div>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </vue-good-table>
      </section>
    </b-card>
  </section>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { mapGetters } from "vuex";
import { localeDateStringFromIsoDateTime, makeErrorToast } from "@/libs/utils";
import { commsService } from "@services";
import { notificationType } from "@models";

export default {
  name: "GroupNotification",
  components: {
    BCard,
    VueGoodTable,
  },
  props: {
    notification: {
      type: Array,
      default: () => [],
    },
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (vm.notification.length === 0) {
        if (vm.isChampionPortal) {
          return vm.$router.push({ name: "champion-program-notifications" });
        }
        return vm.$router.push({
          name: "participants-notifications",
          params: {
            clientSlug: vm.$route.params.clientSlug,
            programPath: vm.$route.params.programPath,
          },
        });
      }
    });
  },
  data() {
    return {
      isLoading: false,
      columns: [
        {
          label: "Message",
          field: "latest_message.0.subject",
          width: "12em",
          sortable: false,
        },
        {
          label: "User",
          field: "created_by.full_name",
          width: "12em",
          sortable: false,
        },
        {
          label: "Created At",
          field: "created_at",
          width: "12em",
          sortable: false,
        },
        {
          label: "Read At",
          field: "read_at",
          width: "12em",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("programs", ["defaultProgramId", "defaultProgram"]),
    ...mapGetters("app", ["isParticipantPortal", "isChampionPortal"]),
  },
  created() {},
  methods: {
    async markAsRead(notification) {
      try {
        const programId = this.$route.params.id || this.defaultProgramId;
        const topicId = notification?.id;
        await commsService.postTopicMessageMarkRead(programId, {
          topics: [{ id: topicId }],
        });
        this.$root.$emit("message-read", notification);
      } catch (e) {
        console.log(e);
        this.$toast(makeErrorToast(e));
      }
    },
  },
  setup() {
    return {
      localeDateStringFromIsoDateTime,
      notificationType,
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/vue/libs/vue-good-table.scss";
</style>
